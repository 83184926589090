.page-title-content h2 {
  font-family: "BPG Arial Caps", sans-serif;
  color: #143b5e;
}
.page-title-content h2:before {
  width: 150px;
}

.about-content .sub-title {
  font-family: "DejaVu Sans", sans-serif;
  color: #da4a2e;
}
.about-content h2 {
  font-family: "BPG Arial Caps", sans-serif;
  font-size: 25px;
  color: #143b5e;
}
.about-content p {
  font-family: "BPG Nino Elite Exp", sans-serif;
  color: #001031;
}

.features-area .content .sub-title {
  font-family: "DejaVu Sans", sans-serif;
  color: #da4a2e;
}
.features-area .content h2 {
  font-family: "BPG Arial Caps", sans-serif;
  font-size: 25px;
  color: #143b5e;
}
.features-area .content p {
  font-family: "BPG Nino Elite Exp", sans-serif;
  color: #001031;
}
.features-area .content .features-list li span {
  font-family: "BPG Arial Caps", sans-serif;
}

