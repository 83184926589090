.footer-area h3 {
  font-family: "BPG Arial Caps", sans-serif;
}
.footer-area .footer-quick-links {
  font-family: "DejaVu Sans", sans-serif;
}
.footer-area .copyright-area {
  margin-top: 0px !important;
}

.footer-contact-info {
  padding: 0;
}
.footer-contact-info li {
  padding: 0 !important;
  display: flex;
  align-items: center;
  margin-bottom: 10px !important;
}
.footer-contact-info li i {
  position: unset !important;
  margin-right: 15px;
  font-size: 30px;
}

