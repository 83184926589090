.footer-area {
  h3 {
    font-family: 'BPG Arial Caps',sans-serif;
  }
  .footer-quick-links {
    font-family: 'DejaVu Sans',sans-serif;
  }
  .copyright-area {
    margin-top: 0px !important;
  }
}
.footer-contact-info {
  padding: 0;
  li {
    padding: 0 !important;
    display: flex;
    align-items: center;
    margin-bottom: 10px !important;
    i {
      position: unset !important;
      margin-right: 15px;
      font-size: 30px;
    }
  }
}