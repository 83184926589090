.sidebar-modal .title {
  font-family: "BPG Nino Elite Exp", sans-serif;
  color: #001031;
}
.sidebar-modal .title h3 {
  font-size: 18px;
  font-family: "BPG Arial Caps", sans-serif;
  color: #143b5e;
  padding-top: 20px;
  margin: 0;
}
.sidebar-modal .title p {
  line-height: 20px;
  margin-top: 8px !important;
}

