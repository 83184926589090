.page-title-content {
  h2 {
    font-family: 'BPG Arial Caps',sans-serif;
    color: #143b5e;
    &:before{
      width: 150px;
    }
  }
}
.about-content {
  .sub-title {
    font-family: 'DejaVu Sans',sans-serif;
    color: #da4a2e;
  }
  h2 {
    font-family: 'BPG Arial Caps',sans-serif;
    font-size: 25px;
    color: #143b5e;
  }
  p {
    font-family: 'BPG Nino Elite Exp',sans-serif;
    color: #001031;
  }
}

.features-area {
  .content {
    .sub-title {
      font-family: 'DejaVu Sans',sans-serif;
      color: #da4a2e;
    }
    h2 {
      font-family: 'BPG Arial Caps',sans-serif;
      font-size: 25px;
      color: #143b5e;
    }
    p {
      font-family: 'BPG Nino Elite Exp',sans-serif;
      color: #001031;
    }
    .features-list {
      li {
        span {
          font-family: 'BPG Arial Caps',sans-serif;
        }
      }
    }
  }
}